import Carousel from 'react-bootstrap/Carousel';
// import ExampleCarouselImage from 'components/ExampleCarouselImage';
import Banner1 from '../assetes/images/banner-img/banner-1.png';
import Mbanner1 from '../assetes/images/banner-img/m-banner-1.png';

import Banner2 from '../assetes/images/banner-img/banner-2.png';
import MBanner2 from '../assetes/images/banner-img/m-banner-2.png';

import Banner3 from '../assetes/images/banner-img/banner-3.png';
import MBanner3 from '../assetes/images/banner-img/m-banner-3.png';

import offer from '../assetes/images/banner-img/offer.webp';



function MainBanner() {
    return (
        <div className='pt-5 pos-rel-ban'>
            <img src={offer} className="offer-img" alt="" />
            <Carousel>
                <Carousel.Item>
                    {/* <img src={Banner1} className='w-100' alt="" srcset="" /> */}
                    <picture>
                        <source media="(min-width:768px)" srcset={Banner1} />
                        <source media="(max-width:768px)" class="w-100" srcset={Mbanner1}
                        />
                        <img src={Banner1} class="img-fluid"
                            alt="Family office investment management" title="Family office investment management"
                        />
                    </picture>
                </Carousel.Item>
                <Carousel.Item>
                    {/* <img src={Banner2} className='w-100' alt="" srcset="" /> */}
                    <picture>
                        <source media="(min-width:768px)" srcset={Banner2} />
                        <source media="(max-width:768px)" class="w-100" srcset={MBanner2}
                        />
                        <img src={Banner2} class="img-fluid"
                            alt="Family office investment management" title="Family office investment management"
                        />
                    </picture>


                </Carousel.Item>
                <Carousel.Item>
                    {/* <img src={Banner3} className='w-100' alt="" srcset="" /> */}
                    <picture>
                        <source media="(min-width:768px)" srcset={Banner3} />
                        <source media="(max-width:768px)" class="w-100" srcset={MBanner3}
                        />
                        <img src={Banner3} class="img-fluid"
                            alt="Family office investment management" title="Family office investment management"
                        />
                    </picture>


                </Carousel.Item>
            </Carousel>
        </div>
    );
}

export default MainBanner;