import React from "react";


function About() {
    return (
        <div className="pt-5">

            <h1 className="pt-5">About</h1>

            <p>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime debitis ut officia rerum adipisci quasi beatae? Fuga, nesciunt nobis sint animi pariatur eaque illum iste vel, sequi ipsum, assumenda omnis.
                Laudantium quam dolores eius odit facilis harum consequuntur accusantium minima perspiciatis, distinctio maiores recusandae cum earum nihil. Facilis amet quos perferendis aut perspiciatis nam officia quam molestiae alias quas. Officiis!
                Vitae, explicabo totam placeat reprehenderit voluptatum ipsam, suscipit dolor illum non harum ducimus repellendus. Aut eos qui adipisci deleniti dolor inventore vero asperiores voluptatum eum, laborum odio, distinctio, repellat sequi?
                Rem nesciunt atque repudiandae dicta doloribus natus molestiae blanditiis iste officiis asperiores perspiciatis iusto aperiam voluptatibus, ea, facere magnam placeat amet, aliquam sit similique ipsa vitae doloremque officia! Atque, officiis.
                Voluptatum facilis numquam velit, illo saepe sint quidem optio fuga minima eius inventore itaque enim odit nam doloribus magnam laudantium! Reprehenderit, excepturi nihil eius sint nulla alias tempore molestiae ab.
            </p>

        </div>
    )
}

export default About;