import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

// Import required modules
import { FreeMode, Pagination } from 'swiper/modules';

export default function GalleryImg({ images }) { // Accept images as props
    return (
        <>
            <Swiper
                slidesPerView={3}
                spaceBetween={30}
                freeMode={true}
                pagination={{
                    clickable: true,
                }}
                modules={[FreeMode, Pagination]}
                className="mySwiper"
                breakpoints={{
                    // Set for mobile screens up to 768px
                    0: {
                        slidesPerView: 1, // Show one slide on mobile
                        spaceBetween: 10, // Adjust spacing as needed for mobile
                    },
                    // For screens larger than 768px
                    768: {
                        slidesPerView: 3, // Show three slides
                        spaceBetween: 50, // Regular spacing
                    },
                }}
            >
                {/* Map over the images array to render each image */}
                {images.map((image, index) => (
                    <SwiperSlide key={index}>
                        <img src={image} alt={`Gallery image ${index + 1}`} className="gal-img" />
                    </SwiperSlide>
                ))}
            </Swiper>
        </>
    );
}
