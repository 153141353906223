import React from "react";
import Accordion from 'react-bootstrap/Accordion';

export default function Faqs() {
    return (
        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
                <Accordion.Header>

                    Why Should I Hire Get Labourers ?

                </Accordion.Header>
                <Accordion.Body>
                    <p className="text-start">
                        Hiring Get Labourers ensures that you receive reliable, skilled and professional labor services tailored to your specific needs. We provide you with
                        <strong>
                            Quality Workforce, Quick and Easy Booking, Flexible and Affordable Solutions, Reliable Service and with Customer Satisfaction
                        </strong>
                    </p>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header>
                    Do You Provide Customized Laborers ?
                </Accordion.Header>
                <Accordion.Body>
                    <p className="text-start">
                        Yes, we do! If you need a helping hand tailored specifically to the work you do, we provide customized labor services. Whether you require specialized skills, specific expertise, or additional support for a unique project, our team can match you with the right laborers to meet your exact needs. Just let us know your requirements, and we’ll ensure you get the perfect fit for the job!
                    </p>
                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
                <Accordion.Header>
                    Do we have to provide equipment or tools ?
                </Accordion.Header>
                <Accordion.Body>
                    <p className="text-start">
                        No, Our laborers come prepared with the required tools and machinery required.
                    </p>
                </Accordion.Body>
            </Accordion.Item>

        </Accordion>

    )

}