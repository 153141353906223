
import React from "react";

function WorkSteps(props) {
    return (
        <div className="py-4 work-step mb-4">
            <img src={props.stepImg} alt="" className="sterps-image" />

            <p className="step-count pt-2">
                {props.stepCount}
            </p>

            <div className="ser-cont">
                <p className="steps mb-0">
                    {props.steps}
                </p>
                <p className="extra-info">
                    {props.extraInfo}
                </p>

            </div>

        </div>
    )
}

export default WorkSteps;
