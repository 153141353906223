import React from "react";

function SerCatgory(props) {
    return (
        <h4 className="ser-catgory mb-0">
            {props.serCatgory}
        </h4>
    );
}

function OurServices(props) {
    return (
        <div className='ser-card mb-5'>

            <div className="ser-pos mb-4">

                <img src={props.ser} alt="" className="ser-img" />

                <h3 className="ser-name py-2 mb-0 ser-pos-abs">
                    {props.serName}
                </h3>
            </div>
            {/* Render multiple categories if passed as children */}
            {props.children}

            <div className="ser-callto-acti mt-3">
                <a href="{props.serlink}" className="know-mor px-3 ">
                    Know More <i class="fa-solid fa-arrow-right"></i>
                </a>

                <a href="#" className="enq-now px-3 ">
                    Enquire Now <i class="fa-solid fa-arrow-right"></i>
                </a>
            </div>
        </div>
    );
}

// Correctly export the components
export { OurServices, SerCatgory };
