import React from 'react';
import './App.css';
import './assetes/style/services.css';
import Header2 from './pages/header2';

import Home from './pages/home';

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Header from './components/header';
import Footer from './components/footer';
import ServicesTemp from './components/servicesSt';
import WeedRemoval from './pages/services/weedRemoval';

import About from './pages/about';

import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">

      <React.StrictMode>
        <Header />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} /> {/* Changed About to Home */}
            <Route path="about" element={<About />} />
            <Route path="servicesSt" element={<ServicesTemp />} />
            <Route path="weedRemoval" element={<WeedRemoval />} />

           {/* <Route path="header2" element={<Header2 />} /> logo of the page */}
           {/* <Route path="tableau" element={<Tableau />} />
            <Route path="excel" element={<Excel />} /> */}
          </Routes>
        </BrowserRouter>
        <Footer />
      </React.StrictMode >

    </div>
  );
}

export default App;
