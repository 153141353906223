import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import TestiStr from './testiStruc';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

// import './styles.css';
import frPaul from '../assetes/images/client-img/fr-paul.jpg'

// import required modules
import { FreeMode, Pagination } from 'swiper/modules';

export default function Testimonials() {
    return (
        <>
            <Swiper
                slidesPerView={3}
                spaceBetween={30}
                freeMode={true}
                pagination={{
                    clickable: true,
                }}
                modules={[FreeMode, Pagination]}
                className="mySwiper"
                breakpoints={{
                    // Set for mobile screens up to 768px
                    0: {
                        slidesPerView: 1, // Show one slide on mobile
                        spaceBetween: 10, // Adjust spacing as needed for mobile
                    },
                    // For screens larger than 768px
                    768: {
                        slidesPerView: 3, // Show three slides
                        spaceBetween: 50, // Regular spacing
                    },
                }}
            >
                <SwiperSlide>

                    <TestiStr

                        clientImg={frPaul}

                        testimonial="Get Labourers made our renovation project stress-free! The team was punctual, professional, and incredibly hardworking. They understood exactly what we needed and delivered beyond our expectations. I highly recommend their services to anyone looking for reliable and skilled laborers."

                        clientName="Fr.Paulose"

                        clientProfession="- Principal (ST.George Hig School)"

                    />

                </SwiperSlide>
                <SwiperSlide>
                    <TestiStr

                        clientImg={frPaul}

                        testimonial="Get Labourers made our renovation project stress-free! The team was punctual, professional, and incredibly hardworking. They understood exactly what we needed and delivered beyond our expectations. I highly recommend their services to anyone looking for reliable and skilled laborers."

                        clientName="Fr.Paulose"

                        clientProfession="- Principal (ST.George Hig School)"

                    />
                </SwiperSlide>
                <SwiperSlide>
                    <TestiStr

                        clientImg={frPaul}

                        testimonial="Get Labourers made our renovation project stress-free! The team was punctual, professional, and incredibly hardworking. They understood exactly what we needed and delivered beyond our expectations. I highly recommend their services to anyone looking for reliable and skilled laborers."

                        clientName="Fr.Paulose"

                        clientProfession="- Principal (ST.George Hig School)"

                    />
                </SwiperSlide>

                <SwiperSlide>
                    <TestiStr

                        clientImg={frPaul}

                        testimonial="Get Labourers made our renovation project stress-free! The team was punctual, professional, and incredibly hardworking. They understood exactly what we needed and delivered beyond our expectations. I highly recommend their services to anyone looking for reliable and skilled laborers."

                        clientName="Fr.Paulose"

                        clientProfession="- Principal (ST.George Hig School)"

                    />
                </SwiperSlide>

                <SwiperSlide>
                    <TestiStr

                        clientImg={frPaul}

                        testimonial="Get Labourers made our renovation project stress-free! The team was punctual, professional, and incredibly hardworking. They understood exactly what we needed and delivered beyond our expectations. I highly recommend their services to anyone looking for reliable and skilled laborers."

                        clientName="Fr.Paulose"

                        clientProfession="- Principal (ST.George Hig School)"

                    />
                </SwiperSlide>


                <SwiperSlide>

                    <TestiStr

                        clientImg={frPaul}

                        testimonial="Get Labourers made our renovation project stress-free! The team was punctual, professional, and incredibly hardworking. They understood exactly what we needed and delivered beyond our expectations. I highly recommend their services to anyone looking for reliable and skilled laborers."

                        clientName="Fr.Paulose"

                        clientProfession="- Principal (ST.George Hig School)"

                    />

                </SwiperSlide>

            </Swiper>
        </>
    );
}