import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ContForm from './cont-form';

function PopupForm(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered

        >
            <Modal.Header closeButton className=''>
                <Modal.Title id="contained-modal-title-vcenter">
                    Enquire Now
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className=' px-5 pop-formbod'>
                <ContForm />
            </Modal.Body>

        </Modal>
    );
}

function FormPop() {
    const [modalShow, setModalShow] = React.useState(false);

    return (
        <>
            <Button variant="primary" onClick={() => setModalShow(true)} className="bok-ser">
                Book Now
            </Button>
            {/* <button className="bok-ser">
                Book Now
            </button> */}

            <PopupForm
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    );
}
// render(<FormPop />);

export default FormPop;
