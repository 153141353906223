import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import "../assetes/main.css";

import Logo from '../assetes/images/get-labourers.png';

function Header() {
    return (
        <div className="navbar-cont">
           
            <Navbar expand="lg" className="nav-rel">
                <Container>
                    <Navbar.Brand href="#home" className="logo-brand-sec text-start">
                        <img src={Logo} className="nav-log" alt="" />
                    </Navbar.Brand>

                    <div className="nsv-abs d-lg-none">
                        <Nav.Link href="#link" className="px-4 call-us-now">
                            <i class="fa-solid fa-phone nav-callbtn"></i>

                            <span className='ps-2'>
                                9748847827
                            </span>

                        </Nav.Link>
                    </div>

                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto d-flex justify-content-between align-items-center w-100">

                            <Nav.Link href="/">
                                Home
                            </Nav.Link>
                            <Nav.Link href="servicesSt">
                                About
                            </Nav.Link>
                            <Nav.Link href="weedRemoval">
                                Our Services
                            </Nav.Link>
                            <NavDropdown title="Dropdown" id="basic-nav-dropdown">

                                <NavDropdown.Item href="#action/3.1">

                                    Action

                                </NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.2">

                                    Another action

                                </NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.3">

                                    Something

                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="#action/3.4">
                                </NavDropdown.Item>
                            </NavDropdown>

                            <Nav.Link href="#link">Contact Us</Nav.Link>
                            <Nav.Link href="#link" className="px-4 py-1 call-us-now">
                                <i class="fa-solid fa-phone nav-callbtn"></i>

                                <span className='ps-2'>
                                    9748847827
                                </span>

                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>


                </Container>
            </Navbar>
        </div>
    );
}

export default Header;