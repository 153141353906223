import React from "react";
import Banner1 from "../../assetes/images/services/weed-removal/weed-removal-banner.png";


import Accordion from 'react-bootstrap/Accordion';
import FormPop from '../../components/formPop'


import sec2Img from "../../assetes/images/services/weed-removal/weed-rem.png";
import dripIrrgaition from "../../assetes/images/services/weed-removal/manual-weed-removal.png";
import sprinklerIrrgation from "../../assetes/images/services/irragition/customIrrigation.png";
import customIrrigation from "../../assetes/images/services/weed-removal/chemical-weed-control.png";


import GalleryImg from "../../components/gallery";

import gal1 from "../../assetes/images/services/irragition/gallery/drip-irrigation1.png";
import gal2 from "../../assetes/images/services/irragition/gallery/drip-irrigation2.png";
import gal3 from "../../assetes/images/services/irragition/gallery/drip-irrigation3.png";
import gal4 from "../../assetes/images/services/irragition/gallery/drip-irrigation4.png";
import gal5 from "../../assetes/images/services/irragition/gallery/sprinkler-irrigation1.png";
import gal6 from "../../assetes/images/services/irragition/gallery/sprinkler-irrigation2.png";
import gal7 from "../../assetes/images/services/irragition/gallery/sprinkler-irrigation3.png";





function WeedRemoval() {
    // gallery images
    const images = [gal1, gal5, gal6, gal2, gal3, gal4, gal7];
    return (
        <section className="">

            <div className="pt-5">
                <img src={Banner1} className="w-100 pt-4" alt="" />
            </div>


            <div className="container">
                <div className="ser-intro pt-5 pb-2">


                    <h1 className="pt-2 pb-2 ser-main-hed">
                        Professional Weed Removal Services
                    </h1>

                    <h2 className="ser-sub-hed">
                        Targeted Weed Control for Lawns, Gardens, and Agricultural Lands
                    </h2>

                    <p className="ser-cm-fnt">
                        Maintaining healthy and vibrant green spaces means keeping invasive weeds at bay. Our professional weed removal services offer tailored solutions that are perfect for all types of environments, whether you're managing a small garden, a sprawling farm, or a landscaped park.
                    </p>

                    <p className="ser-cm-fnt">
                        Our team uses safe and efficient techniques to target weeds without harming the surrounding plants. With expertise across different terrains and vegetation types, we ensure that each treatment is customized to meet your specific needs. Trust us to help you reclaim your green space and keep it thriving year-round.
                    </p>
                </div>

                <div className="prob-sol pt-5 pb-2">

                    <h2 className="ser-mnh2 pb-4">
                        Why Choose Get Labourers ?
                    </h2>

                    <div className="row d-flex align-items-center">
                        <div className="col-lg-6">
                            <img src={sec2Img} alt="" srcset="" className="w-100" />
                        </div>

                        <div className="col-lg-6">
                            <p className="ser-cm-fnt">
                                At Get Labourers, we bring years of hands-on experience from rural Bangalore, where managing land and controlling weeds is a daily task. As local experts, we understand the unique challenges posed by the region’s landscape and climate, making us the ideal choice for your weed removal needs. Our team uses a variety of eco-friendly, efficient techniques to tackle weed problems, from manual removal to chemical and mulching solutions.
                            </p>
                        </div>

                    </div>

                    <h3 className="py-4 mt-3">
                        Key Benefits
                    </h3>

                    <div className="row d-flex justify-content-around">
                        <div className="col-lg-5 text-start benift">
                            <i class="fa-solid fa-check"></i>
                            Efficient water usage, saving costs.
                        </div>

                        <div className="col-lg-5 text-start benift">
                            <i class="fa-solid fa-check"></i>
                            Improved plant health and yield.
                        </div>

                        <div className="col-lg-5 text-start benift">
                            <i class="fa-solid fa-check"></i>
                            Minimal maintenance systems.
                        </div>

                        <div className="col-lg-5 text-start benift">
                            <i class="fa-solid fa-check"></i>
                            Affordable and customizable for various spaces.
                        </div>
                    </div>

                </div>


                <div className="prob-sol pt-5 pb-2">

                    <h2 className="ser-mnh2 pb-4">
                        Our Expert Weed Removal Services
                    </h2>

                    <div className="row d-flex align-items-center">


                        <div className="col-lg-6 px-lg-0 px-3 pt-4 pt-lg-0 text-start order-2 order-md-1">
                            <h3 className="text-start ">

                                <span> Manual Weed Removal  </span>
                                {/* <span>
                                    <img src={flow2} className="flow-ico" alt="" />
                                </span> */}
                            </h3>
                            <p className="ser-cm-fnt pe-lg-4">
                                Our manual weed removal service offers precise, hands-on control of unwanted plants in your garden or green space. Our team carefully removes weeds by hand or with specialized tools, targeting invasive plants without disturbing your healthy vegetation. This method is ideal for smaller areas or delicate plant beds, ensuring a thorough and eco-friendly approach to keeping your space beautiful and weed-free.

                            </p>

                            <p className="ser-cm-fnt pe-lg-4">
                                Let us handle the hard work so you can enjoy a vibrant, thriving landscape!
                            </p>

                            <FormPop />

                        </div>


                        <div className="col-lg-6 px-0 order-1 order-md-2">
                            <img src={dripIrrgaition} alt="" srcset="" className="w-100 irg-img" />
                        </div>

                    </div>

                    <div className="row d-flex align-items-center py-5">
                        <div className="col-lg-6 px-0 py-lg-5">
                            <img src={sprinklerIrrgation} alt="" srcset="" className="w-100 irg-img" />
                        </div>


                        <div className="col-lg-6 px-lg-0 px-3 pt-4 pt-lg-0 ps-lg-4 text-start">
                            <h3 className="text-start ">

                                <span> Plastic Mulching </span> <br />
                                <span className="ser-sub">
                                    (covering the soil around plants with durable plastic)
                                </span>
                                {/* <span>
                                    <img src={flow2} className="flow-ico" alt="" />
                                </span> */}
                            </h3>
                            <p className="ser-cm-fnt">

                                Our plastic mulching service is an effective way to keep weeds at bay while supporting healthy plant growth. By covering the soil around plants with durable plastic mulch, we block sunlight to prevent weeds from germinating. This method also helps retain soil moisture, regulate temperature, and reduce erosion, creating an ideal environment for your plants to thrive.

                            </p>

                            <p className="ser-cm-fnt">

                                Plastic mulching is ideal for gardens, landscaped areas, and farms, offering a low-maintenance solution that minimizes the need for manual weeding and conserves water. Let us help you maintain a clean, weed-free space with this practical and efficient approach!
                            </p>

                            <FormPop />

                        </div>
                    </div>

                    <div className="row d-flex align-items-center">
                        <div className="col-lg-6 px-0 text-start pe-lg-4 order-2 order-md-1 px-lg-0 px-3 pt-4 pt-lg-0">
                            <h3 className="text-start ">

                                <span> Chemical Weed Control  </span>
                                {/* <span>
                                    <img src={flow2} className="flow-ico" alt="" />
                                </span> */}
                            </h3>
                            <p className="ser-cm-fnt">
                                Our chemical weed control service uses targeted, professional-grade herbicides to eliminate stubborn weeds effectively and safely. Whether dealing with invasive plants across large fields or specific problem areas, we apply selective herbicides designed to minimize impact on surrounding plants and soil health. Our team is trained to handle these treatments responsibly, ensuring a balance between powerful weed removal and environmental safety.
                            </p>

                            <p className="ser-cm-fnt">

                                Choose our chemical weed control service for efficient, long-lasting results that keep your green spaces clear and thriving.
                            </p>

                            <FormPop />

                        </div>


                        <div className="col-lg-6 px-0 order-1 order-md-2">
                            <img src={customIrrigation} className="w-100 irg-img" alt="" srcset="" />
                        </div>



                    </div>

                </div>

            </div>


            <div className="gallery py-5 px-lg-3">
                <h2 className="ser-mnh2 pb-4">
                    FAQ's
                </h2>

                <div className="container">
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                1. What is the best method for weed removal?
                            </Accordion.Header>
                            <Accordion.Body className="">
                                <p>
                                    The best method depends on the type of weeds, the size of the area, and your preferences. We offer manual removal, chemical control, plastic mulching, and soil solarization. Our experts will assess your space and recommend the most effective and eco-friendly solution.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>
                                2. Is chemical weed control safe for my plants?
                            </Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    Yes, we use selective herbicides that target only the weeds, leaving your plants unharmed. Our team ensures that the herbicide application is done carefully and responsibly to minimize any environmental impact.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="2">
                            <Accordion.Header>3. How long does it take to remove weeds from my garden or lawn?
                            </Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    The time it takes depends on the size of the area and the weed problem. Manual removal of weeds in smaller areas may take a few hours, while larger spaces or more persistent weed infestations may require multiple treatments.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3">
                            <Accordion.Header>4. Can I control weeds without chemicals?</Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    Absolutely! We offer several organic options, such as mulching, soil solarization, and manual removal. These methods are safe for your plants and the environment, while effectively controlling weed growth.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="4">
                            <Accordion.Header>5. Will weed removal services damage my soil?</Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    We take great care to ensure that weed removal methods, including chemical and mechanical treatments, do not harm your soil. In fact, techniques like mulching and soil solarization can improve soil health and fertility over time.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="5">
                            <Accordion.Header>6. How often should I schedule weed removal services?</Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    We recommend scheduling weed removal regularly to prevent weeds from regrowing. The frequency will depend on the type of weeds and the size of your area. For larger areas or ongoing issues, a seasonal or monthly visit might be necessary.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="6">
                            <Accordion.Header>7. Are your weed removal services available for both residential and commercial properties?</Accordion.Header>
                            <Accordion.Body>
                                <p>
                                Yes! We offer weed removal services for both residential gardens and commercial landscapes. Whether you need to manage weeds in your backyard or on a large commercial property, we have the expertise and tools to get the job done efficiently.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="7">
                            <Accordion.Header>8. How can I prevent weeds from growing back after removal?</Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    Prevention is key. After weed removal, we recommend using mulch, ground covers, or soil solarization to block sunlight and prevent weed seeds from germinating. Regular maintenance and care also help keep weeds under control.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>



                    </Accordion>

                </div>

            </div>



            <div className="gallery py-5 px-3">
                <div className="container">
                    <h2 className="ser-mnh2 pb-4">
                        Gallery
                    </h2>
                    <GalleryImg images={images} />
                </div>
            </div>


        </section >
    )
}

export default WeedRemoval;