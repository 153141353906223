// import './App.css';
import Banner from '../components/banner';
// testing
import BannerTwo from '../components/banner2';
import MainBanner from '../components/bannerCor';

import MainHeading from '../components/main-heading';
import WorkSteps from '../components/workSteps';
import { OurServices, SerCatgory } from '../components/services';
import Testimonials from '../components/testimonials';
import Faqs from '../components/faqs';
import Gallery from '../components/gallery1';
// import Footer from '../components/footer';
import SerImages from '../components/bannertop';

import bookSer from '../assetes/images/working-steps/book-service.png';
import consultation from '../assetes/images/working-steps/consultation.png';
import quotation from '../assetes/images/working-steps/quotation.png';
import scheduleWork from '../assetes/images/working-steps/schedule-work.png';
import work from '../assetes/images/working-steps/gets-work.png';
import quality from '../assetes/images/working-steps/high-quality.png';
import payment from '../assetes/images/working-steps/payment-method.png';
import support from '../assetes/images/working-steps/ongoing-support.png';
import ser1Img from '../assetes/images/services/banner1.png';
import weedRemoval from '../assetes/images/services/weed-removal.png';
import manure from '../assetes/images/services/manure.jpeg';
import sweeping from '../assetes/images/services/sweeping.jpg';
import garbage from '../assetes/images/services/garbage.jpg';
import pestControl from '../assetes/images/services/weed-removal/chemical-weed-control.png';
import foggingMachine from '../assetes/images/services/fogging-machine.jpg';
import sewageCle from '../assetes/images/services/sewage-cleaning.png';
import irrigImg from '../assetes/images/services/irragition/drip-irrgaition.png';

import Banner1 from '../assetes/images/banner-img/banner-1.png';

import GalleryImg from '../components/gallery';
import gal1 from '../assetes/images/services/fogging-machine.jpg';
import gal2 from '../assetes/images/services/fogging-machine2.jpg'
import gal3 from '../assetes/images/services/pexels-dibakar-roy-2432543-13731025.jpg'


// import { ContactUs } from './components/emailjs';


import 'bootstrap/dist/css/bootstrap.min.css';

function Home() {

  // gallery images
  const images = [foggingMachine, gal1, gal2, gal3];

  return (
    <div className="home">

      {/* <Header /> */}
      {/* <BannerTwo /> */}

      <div className="bn-cont pt-5">
        <MainBanner />
      </div>

      {/* Our services */}

      <section className='py-5 our-services'>
        <MainHeading

          mainheading="Our Services"

          mTagline="Your Project, Our Priority"

        />
        <div className='container'>
          <div className='row'>

            <div className='col-lg-4 col-6'>

              <OurServices
                ser={ser1Img}
                serName="Digging Services"
              >
                {/* Pass the SerCatgory components as children */}
                <SerCatgory serCatgory="1 .  Manual Digging" />
                <SerCatgory serCatgory="2 . Machine Digging" />

              </OurServices>





            </div>

            <div className='col-lg-4 col-6'>

              <OurServices
                ser={weedRemoval}
                serName="Weed Removal"
                serlink ="weedRemoval"
              >
                {/* Pass the SerCatgory components as children */}
                <SerCatgory serCatgory="1 . Manual weed control" />
                <SerCatgory serCatgory="2 . Chemical Weed Control" />


              </OurServices>

            </div>

            <div className='col-lg-4 soil-enrich'>


              <OurServices
                ser={manure}
                serName="Organic Manuring & Soil Enrichment"
              >
                {/* Pass the SerCatgory components as children */}
                <SerCatgory serCatgory="1 . Cow Dung Manure" />
                <SerCatgory serCatgory="2 . Goat / Sheep Manure" />

              </OurServices>

            </div>

            <div className='col-lg-4 col-6'>

              <OurServices
                ser={irrigImg}

                serName="Irrigation Solutions"
              >
                <p className="serMinCont">
                  We provide various Irrigation Solutions from Drip Irrigation to Sprinkler Systems. Customize Solutions for Farms, Gardens, and Green Spaces.
                </p>

              </OurServices>


            </div>

            <div className='col-lg-4 col-6'>

              <OurServices
                ser={pestControl}

                serName="Plant Pest Control"
              >
                <p className="serMinCont">
                  We provide effective plant pest control services, from targeted treatments for common garden pests to preventive care solutions, ensuring your plants stay healthy and thriving.
                </p>

              </OurServices>


            </div>

            <div className='col-lg-4 col-6'>

              <OurServices
                ser={sweeping}
                serName="Outdoor Cleaning"
              >
                <p className="serMinCont">
                  We provide comprehensive outdoor cleaning services from A to Z - from sweeping and pressure washing to driveway scrubbing, and more.
                </p>

              </OurServices>


            </div>

            <div className='col-lg-4 col-6'>

              <OurServices
                ser={garbage}

                serName="Garbage Cleaning"
              >
                <p className="serMinCont">
                  We offer complete garbage cleaning services, from regular waste removal and bin washing to thorough debris cleanup and disposal, keeping your spaces spotless.
                </p>

              </OurServices>


            </div>



            <div className='col-lg-4 col-6'>

              <OurServices
                ser={foggingMachine}

                serName="Mosquito Fogging"
              >
                <p className="serMinCont">

                  We eliminate mosquitoes at the root level, from larvae to adult mosquitoes, using advanced fogging techniques to provide you with a long-term solution for a mosquito-free environment.

                </p>

              </OurServices>


            </div>



            <div className='col-lg-4 col-6'>

              <OurServices
                ser={sewageCle}

                serName="Gutter & Sewage Cleaning"
              >
                <p className="serMinCont">

                  Keep your property safe and clean with our professional gutter and sewage cleaning services. We clear blockages, remove debris, and ensure smooth water flow to prevent damage and maintain hygiene

                </p>

              </OurServices>


            </div>


            <a href='all-ser-btn' className='all-ser-btn'>
              <span className=''>  All Services </span>
            </a>


          </div>
        </div>
      </section>

      {/* how we work */}
      <section className='py-5 howWeWork'>
        <MainHeading

          mainheading="How We Work"

          mTagline="From Start to Finish, We’ve Got You Covered"

        />
        <div className='container'>
          <div className='row'>

            <div className='col-md-3 col-6'>
              <WorkSteps

                stepImg={bookSer}
                stepCount="Step 1"
                steps="Book a Service"

              />
            </div>

            <div className='col-md-3 col-6'>
              <WorkSteps

                stepImg={consultation}
                stepCount="Step 2"
                steps="Consultation & Site Visit"
                extraInfo="(if necessary)"

              />
            </div>

            <div className='col-md-3 col-6'>
              <WorkSteps

                stepImg={quotation}
                stepCount="Step 3"
                steps="Receive a Quotation"

              />
            </div>

            <div className='col-md-3 col-6'>
              <WorkSteps

                stepImg={scheduleWork}
                stepCount="Step 4"
                steps="Schedule the Work"

              />
            </div>

            <div className='col-md-3 col-6'>
              <WorkSteps

                stepImg={work}
                stepCount="Step 5"
                steps="Our Team Gets to Work"

              />
            </div>

            <div className='col-md-3 col-6'>
              <WorkSteps

                stepImg={quality}
                stepCount="Step 6"
                steps="Quality Assurance"

              />
            </div>

            <div className='col-md-3 col-6'>
              <WorkSteps

                stepImg={payment}
                stepCount="Step 7"
                steps="Payment & Feedback"

              />
            </div>

            <div className='col-md-3 col-6'>
              <WorkSteps

                stepImg={support}
                stepCount="Step 8"
                steps="Ongoing Support
"

              />
            </div>

          </div>
        </div>

      </section>

      {/* Testimonials*/}

      <section className='py-5'>
        <MainHeading

          mainheading="Testimonials"

          mTagline="Trusted by Many, Recommended by All"

        />
        <div className='container'>
          <Testimonials />
        </div>
      </section>


      {/* FAQ'S*/}

      <section className='py-5 cm-bg text-white faqs'>
        <MainHeading

          mainheading="FAQ'S"

          mTagline="Trusted by Many, Recommended by All"

        />
        <div className='container'>
          <Faqs />
        </div>
      </section>

      {/* form testing */}

      {/* <ContactUs /> */}


      {/* Gallery*/}

      <section className='py-5'>
        <MainHeading

          mainheading="Gallery"

          mTagline="Trusted by Many, Recommended by All"

        />
        <div className='container'>
          <GalleryImg images={images} />
        </div>
      </section>



      {/* footer */}

      {/* <Footer /> */}


    </div>
  );
}

export default Home;
