import React from "react";


export default function TestiStr(props) {
    return (
        <div className="test-sec">
            <img src={props.clientImg} alt="" className='client-img' />
            <div className="test-cont">
                <p className='tesr-p'>
                    <span>"</span>
                    {props.testimonial}

                    <span>"</span>
                </p>

                <p className="cust-name mb-0">
                    {props.clientName}
                </p>

                <p className="cust-prof">
                    {props.clientProfession}
                </p>

            </div>
        </div>
    )
}