// import React, { useRef, useState } from 'react';
import React, { useState, useRef } from "react";
import emailjs from '@emailjs/browser';
import birdImg from '../assetes/images/bird.png';

const ContForm = () => {
    // useRef to reference the form
    const form = useRef();

    let [gtname, setName] = useState();
    let [gtphno, setPhno] = useState();
    let [gtemail, setEmail] = useState();
    let [gtname1, setName1] = useState();

    // name
    let getinpval = (event) => {
        setName(event.target.value)
    }

    // phone number
    let getph = (event) => {
        setPhno(event.target.value)
    }

    // mail
    let getemail = (event) => {
        setEmail(event.target.value)
    }

    // Form submit handler
    let FormSumit = (event) => {
        event.preventDefault();

        if (!gtname) {
            setName1("Please Enter A Valid Name");
        } else if (!gtphno) {
            setName1("Phone number is required");
        } else if (gtphno.length !== 10) {
            setName1("Number should be of 10 digits");
            // } else if (!gtemail) {
            //     setName1("Please enter an email id");
        } else {
            emailjs
                .sendForm('service_7fa7pby', 'template_ujgwgku', form.current, 'Zl3utNCtSxSwPImNy')
                .then(
                    () => {
                        setName1("Thank you, form Submitted Successfully");
                    },
                    (error) => {
                        setName1('FAILED... ' + error.text);
                    }
                );
        }
    }

    return (
        <div>
            <form ref={form} id="enquiry_form_footer">
                {/* <h4 className="pb-3 fw-bold ftr-hed">
                    Contact To Know More

                   

                </h4> */}
                <div className="row">
                    <div id="footer-note" className={`${(!gtname || !gtphno || gtphno.length !== 10 || !gtemail) ? 'text-danger' : 'txt-green'}`}>{gtname1}</div>
                    <div className="col-md-12 px-0">
                        <input
                            type="text"
                            placeholder="Name*"
                            name="user_name"
                            className="mb-4 bord w-100"
                            onChange={getinpval}
                        />
                    </div>
                    <div className="col-md-12 px-0 ">
                        <input
                            type="number"
                            placeholder="Phone Number*"
                            name="user_phone"
                            className="mb-4 bord w-100"
                            onChange={getph}
                        />
                    </div>
                    <div className="col-md-12 px-0 col-12 col-lg-12">
                        <input
                            type="email"
                            placeholder="Email*"
                            name="user_email"
                            className="mb-4 bord w-100"
                            onChange={getemail}
                        />
                    </div>
                    <div className="col-md-12 px-0 col-12 col-lg-12">
                        <textarea
                            placeholder="Message"
                            name="message"
                            className="bord w-100"
                        >
                        </textarea>
                    </div>
                    <div className="col-md-12 px-0 col-12 col-md-12 mt-3">
                        <button
                            className="sbtn-form px-4 py-1 d-block mx-auto"
                            type="submit"
                            onClick={FormSumit}
                        >
                            SUBMIT  <span>
                                <img src={birdImg} alt="" className="bird-img" />
                            </span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default ContForm;