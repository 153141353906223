import React from "react";


function MainHeading(props) {
    return (
        <div className="heading-section">
            <h2 className="heading mb-0">
                {props.mainheading}
            </h2>

            <p className="m-tagline">
                <span>"</span>   {props.mTagline} <span>"</span>
            </p>




        </div>
    )
}

export default MainHeading;